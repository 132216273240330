/** @jsx jsx */
import { jsx, Themed } from "theme-ui"
import { PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ location }: PageProps) => {
  return (
    <Layout page="/404">
      <SEO title="404: Not Found" path={location.pathname} pageType="other" />
      <main>
        <Themed.div sx={{ marginX: "3em" }}>
          <Themed.h1 sx={{ fontSize: 5 }}>
            This is not the page you are looking for.{" "}
          </Themed.h1>
          <Themed.p>Check out the blog to find the right blog post? </Themed.p>
        </Themed.div>
      </main>
    </Layout>
  )
}

export default NotFoundPage
